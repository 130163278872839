export const SCRATCH = "SCRATCH";
export const WOOF = "WOOF";
export const WEB = "WEB";
export const ADVENTURE = "ADVENTURE";
export const PUZZLE = "PUZZLE";
export const ARCADE = "ARCADE";
export const MULTIPLAYER = "MULTIPLAYER";
export const INTRO2 = "INTRO TO SCRATCH";
export const INTRO1 = "INTRO TO CODING";
export const PYTHON = "PYTHON";
export const CHALLENGES = "CHALLENGES";