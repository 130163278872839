export const INTRO_TO_CODING = "Intro to Coding";
export const INTRO_TO_SCRATCH = "Intro to Scratch";
export const SCRATCH = "Scratch";
export const WOOF = "Woof";
export const WEB = "Web";
export const PYTHON = "Python";
export const CHALLENGES = "Challenges";
export const STUDENT = "Student";
export const SCRATCHJR = "Scratch Jr.";
export const CODE4CHANGE = "Code 4 Change";
export const UNTUTORIALS = "Untutorials";
